<template>
  <div class="relative">
    <v-select
      v-model="time"
      :active="menu1 || menu2"
      :items="items"
      :disabled="disabled"
      :label="label"
      :menu-props="{
        closeOnContentClick: true,
      }"
      density="compact"
      variant="outlined"
      rounded="lg"
      item-title="display"
      item-value="time"
      hide-details
    >
      <template #selection="{ item }">
        {{ format(item.value) }}
      </template>
      <template #prepend-item>
        <div>
          <div class="mx-2 mb-2">
            <v-btn class="text-none" block
              >Enter with picker

              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                activator="parent"
                min-width="auto"
                location="bottom"
                transition="slide-y-transition"
                offset="15"
              >
                <v-confirm-edit
                  v-model="time2"
                  @save="exitPicker"
                  @cancel="exitPicker"
                >
                  <template v-slot:default="{ model: proxyModel, actions }">
                    <v-card class="mx-auto">
                      <v-time-picker v-model="proxyModel.value" format="24hr" />
                      <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <component :is="actions"></component>
                      </template>
                    </v-card>
                  </template>
                </v-confirm-edit>
              </v-menu>
            </v-btn>
          </div>
          <v-divider />
        </div>
      </template>
      <template #append-item>
        <div>
          <v-divider />
          <div class="mx-2 mt-2">
            <v-btn class="text-none" block
              >Enter with picker

              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                activator="parent"
                min-width="auto"
                location="bottom"
                transition="slide-y-transition"
                offset="15"
              >
                <v-confirm-edit
                  v-model="time2"
                  @save="exitPicker"
                  @cancel="exitPicker"
                >
                  <template v-slot:default="{ model: proxyModel, actions }">
                    <v-card class="mx-auto">
                      <v-time-picker v-model="proxyModel.value" format="24hr" />
                      <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <component :is="actions"></component>
                      </template>
                    </v-card>
                  </template>
                </v-confirm-edit>
              </v-menu>
            </v-btn>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { timeItems } from "../private/opening-hours/utils";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  name: "TimeInput",
  props: {
    modelValue: { type: [String, Number] },
    label: { type: String },
    disabled: { type: Boolean, default: false },
    min: { type: [String, Number] },
    max: { type: [String, Number] },
    use24hrs: { type: Boolean, default: false },
  },
  setup(props) {
    const time = useVModel(props);
    const menu1 = ref(false);
    const menu2 = ref(false);

    const items = computed(() =>
      timeItems(
        withValue(props.min, (v) => Number(v)),
        withValue(props.max, (v) => Number(v)),
        props.use24hrs
      )
    );

    const time2 = computed({
      set(value: string | undefined) {
        if (value) {
          const { hours, minutes } = parseTime(value);
          time.value = hours * 60 + minutes;
        }
      },
      get(): string | undefined {
        if (props.modelValue) {
          return formatMinutes(Number(props.modelValue), true);
        }
      },
    });

    const format = (minutes: string | number) => {
      return formatMinutes(Number(minutes), props.use24hrs);
    };

    const usePicker1 = () => {
      menu1.value = true;
    };

    const usePicker2 = () => {
      menu2.value = true;
    };

    const exitPicker = () => {
      menu1.value = false;
      menu2.value = false;
    };

    return {
      time,
      time2,
      menu1,
      menu2,
      items,
      usePicker1,
      usePicker2,
      exitPicker,
      format,
    };
  },
});
</script>
